<template>
  <div>
    <div class="add">
      <!-- 搜索医生：
            <el-input placeholder="请输入精确的姓名或电话号" clearable prefix-icon="el-icon-search" v-model="search"
                style="width: 15%;margin-left: 20px;margin-right: 20px;" @clear="clearsearch">
            </el-input> -->
      <!-- 选择患者留言状态： -->
      <el-select v-model="select" placeholder="选择患者留言状态" size="small" style="width: 160px;">
        <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" style="margin-left:10px" size="small" @click="query">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%" fit>
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="create_time" label="时间" width="180">
        <template slot-scope="scope">
          <span>
            {{scope.row.create_time.split('.')[0]}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="cust_name" label="姓名" width="140"></el-table-column>
      <el-table-column prop="tel" label="电话" width="140"></el-table-column>
      <el-table-column prop="name" label="医生" width="100"></el-table-column>
      <el-table-column prop="msg" label="内容"></el-table-column>
      <el-table-column width="190">
        <template slot-scope="scope">
          <div class="opt">
            <!-- <a @click="lookmore(scope.row)">查看</a> -->
            <p>
              <el-button size="mini" type="primary" icon="el-icon-edit-outline" v-if="scope.row.status == 0" @click="examine(scope.row)">审核</el-button>
              <el-button size="mini" type="danger" v-if="scope.row.status == 1" @click="back(scope.row,scope.$index,'user')">退审</el-button>
              <el-button size="mini" type="primary" icon="el-icon-service" v-if="scope.row.status == 1" @click="reply(scope.row)">客服回复</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" v-if="scope.row.status == 0" @click="del(scope.row,scope.$index)">删除</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <el-dialog title="查看留言详情" :visible.sync="lookmoremsg" style="text-align: left;">
      <p>{{row.cust_name == ''?'匿名用户':row.cust_name}}：<span>{{row.msg}}</span></p>
      <p v-if="replylist.length > 0" v-for="item in replylist">{{item.cust_name}}：<span>{{item.msg}}</span></p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="lookmoremsg = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="回复留言" :visible.sync="replymsg">
      <el-form class="hdtc" label-width="170px">
        <el-form-item label="客服姓名">
          <el-input type="input" style="float: left;" v-model="name" autocomplete="off" placeholder="请输入客服姓名">
          </el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" style="float: left;" v-model="content" autocomplete="off" placeholder="请输入内容">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="replymsg = false;">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      lookmoremsg: false,
      replymsg: false,
      name: "",
      content: "",
      search: "",
      select: "",
      options: [
        { value: "未审核", id: 0 },
        { value: "审核", id: 1 },
      ],
      doc_id: "",
      row: "",
      cust_id: "",
      replylist: [],
      select2: "",
      options2: [
        { value: "未审核", id: 0 },
        { value: "审核", id: 1 },
      ],
    };
  },
  mounted() {
    this.cust_id = sessionStorage.getItem("cust_id");
  },
  methods: {
    getmsg(page) {
      this.axios
        .get(
          "/gu/get_gu_message_by_review?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&type=cust&status=" +
            this.select
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCount = res.data.count;
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getmsg(currentPage);
    },
    clearsearch() {
      this.list = [];
    },
    query() {
      this.list = [];
      this.getmsg(1);
    },
    lookmore(row) {
      //查看留言
      console.log(row);
      this.lookmoremsg = true;
      this.row = row;
      this.replylist = row.reply.reverse();
    },
    examine(row) {
      //审核
      this.axios
        .post("/gu/publish_msg", this.qs.stringify({ id: row.id, status: 1 }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.$message({
              message: "审核成功",
              type: "success",
            });
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back(row, index, type) {
      //退审
      this.axios
        .post("/gu/publish_msg", this.qs.stringify({ id: row.id, status: 0 }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.$message({
              message: "退审成功",
              type: "success",
            });
            if (type == "user") {
              this.list.splice(index, 1);
            } else {
              this.list[index].reply = "";
            }
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reply(row) {
      //回复
      this.replymsg = true;
      this.row = row;
      console.log(row);
    },
    sub() {
      if (this.name == "" || this.content == "") {
        this.$message({
          message: "请填写完整信息",
          type: "error",
        });
        return;
      }
      this.axios
        .post(
          "/gu/reply_msg",
          this.qs.stringify({
            cust_name: this.name,
            cust_id: this.cust_id,
            msg: this.content,
            resp_id: this.row.id,
            doc_id: this.row.doc_id,
          })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.$message({
              message: "回复成功",
              type: "success",
            });
            this.replymsg = false;
            // this.reload();
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该留言, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_message", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
</style>